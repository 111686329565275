<template>
  <div class="action-item-root-container">
    <avatar-item
      :size="36"
      :name="dataSource.create_user_name"
      :img_url="dataSource.img_url"
      style="margin-right: 15px"
    />
    <div style="flex: 1; display: flex; flex-direction: column">
      <span style="font-size: 15px; color: #000000; margin-bottom: 2px"
        >{{ dataSource.create_user_name }}更新了付款请求单</span
      >
      <span style="font-size: 12px; color: #000000">{{
        dataSource.create_time
      }}</span>
    </div>
  </div>
</template>

<script>
import MyAvatar from "../MyAvatar";
export default {
  components: {
    "avatar-item": MyAvatar,
  },
  props: {
    dataSource: Object,
  },
};
</script>

<style scoped>
.action-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>