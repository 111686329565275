<template>
  <my-drawer ref="moreDrawer" title="付款计划备注" :transfer="false">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-textarea ref="formMore" title="描述" :rows="10" v-model="more" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
  },
  data() {
    return {
      plan_id: 0,
      more: "",
    };
  },
  methods: {
    ...mapActions({
      planSaveMoreAction: "planSaveMore",
    }),
    open(more, plan_id) {
      this.plan_id = plan_id;
      this.more = more;
      this.$refs.moreDrawer.open();
    },
    close() {
      this.$refs.moreDrawer.close();
    },
    submit() {
      this.planSaveMoreAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id: this.plan_id,
        more: this.more,
      })
        .then(() => {
          this.$emit("refreshInfo");
          this.$refs.moreDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.board-form-image {
  width: 100px;
  height: 118px;
}
</style>