<template>
  <div class="action-panel-root-container">
    <span
      style="
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
      "
      >动作</span
    >
    <div class="action-panel-main-container">
      <action-item
        v-for="(item, index) in dataSource"
        :key="index"
        :data-source="item"
        style="margin-bottom: 10px; width: 100%; min-width: 170px"
      />
    </div>
  </div>
</template>

<script>
import ActionItem from "../Item/ActionItem";
export default {
  components: {
    "action-item": ActionItem,
  },
  props: {
    dataSource: Array,
  },
};
</script>

<style scoped>
.action-panel-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
}
.action-panel-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>