<template>
  <div class="milestone-event-item-root-container">
    <img
      :src="icons.milestoneType2"
      style="width: 30px; height: 30px; margin-right: 15px"
    />
    <div class="milestone-event-item-title-container">
      <span style="font-size: 13px; font-weight: 500">{{
        dataSource.name
      }}</span>
      <span style="font-size: 12px; font-weight: 500"
        >创建者：{{ dataSource.create_user_name }}</span
      >
    </div>
    <fee
      :amount="dataSource.total_amount"
      :primarySize="12"
      :secondarySize="12"
    />
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
export default {
  components: {
    fee: Fee,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.milestone-event-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #f6f7f8;
}
.milestone-event-item-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>