<template>
  <div class="plan-milestone-page-root-container">
    <div class="plan-milestone-detail-main-container">
      <project-info :data-source="projectDetail" :id="payPlanId" :type="2" />
      <milestone-reference :data-source="milestones" />
    </div>
    <div class="plan-milestone-detail-main-container" style="margin: 0 50px">
      <div class="plan-milestone-page-row" style="margin-bottom: 10px">
        <span
          style="
            font-size: 23px;
            font-weight: bold;
            color: #000000;
            max-width: 300px;
          "
          >{{ planDetail.name }}</span
        >
        <img
          v-if="canShowBtn"
          :src="icons.edit"
          style="width: 18px; height: 18px; cursor: pointer"
          @click="showDrawer"
        />
      </div>
      <div class="plan-milestone-page-row">
        <span class="plan-milestone-page-row-key">付款计划ID</span>
        <span class="plan-milestone-page-row-val">{{
          planDetail.pay_plan_id
        }}</span>
      </div>
      <div class="plan-milestone-page-row">
        <span class="plan-milestone-page-row-key">创建时间</span>
        <span class="plan-milestone-page-row-val">{{ planCreateTime }}</span>
      </div>
      <div class="plan-milestone-page-row">
        <span class="plan-milestone-page-row-key">创建者</span>
        <span class="plan-milestone-page-row-val">{{
          planDetail.create_user_name
        }}</span>
      </div>
      <div class="plan-milestone-page-row" style="margin-bottom: 30px">
        <span class="plan-milestone-page-row-key">关联的项目</span>
        <span class="plan-milestone-page-row-val">{{
          planDetail.project_name
        }}</span>
      </div>
      <div class="plan-milestone-page-row">
        <span class="plan-milestone-page-row-key">引用里程碑事件</span>
        <span class="plan-milestone-page-row-val">{{ event_count }}个</span>
      </div>
      <div class="plan-milestone-page-row">
        <span class="plan-milestone-page-row-key">引用方式</span>
        <span class="plan-milestone-page-row-val">{{ reference_method }}</span>
      </div>
      <div class="plan-milestone-page-row" style="margin-bottom: 20px">
        <span class="plan-milestone-page-row-key">最近更新于</span>
        <span class="plan-milestone-page-row-val">{{ planUpdateTime }}</span>
      </div>
      <div class="plan-milestone-page-row">
        <span class="plan-milestone-page-row-key">备注信息</span>
        <span
          v-if="canShowBtn"
          @click="updateMore"
          style="
            font-size: 12px;
            font-weight: 500;
            color: #3b73bb;
            cursor: pointer;
          "
          >更新</span
        >
      </div>
      <div class="plan-milestone-detail-remark-container">
        {{ planDetail.more }}
      </div>
    </div>
    <div class="plan-milestone-detail-main-container">
      <Button
        v-if="canShowBtn"
        @click="sync"
        type="primary"
        style="margin-bottom: 30px"
        >同步</Button
      >
      <action-panel :data-source="action_list" style="max-height: 240px" />

      <accessory-reference
        :data-source="accessories"
        :can-show-btn="canShowBtn"
        @upload="upload"
        style="flex: 1; margin-top: 20px"
      />
      <accessories-drawer ref="accessoriesDrawer" @add="add" />
      <plan-more-drawer @refreshInfo="refreshInfo" ref="planMoreDrawer" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import PlanMoreDrawer from "../Drawer/PlanMoreDrawer";
import ActionPanel from "../../../components/Panel/ActionPanel";
import ProjectInfo from "../../../components/Panel/ProjectInfo";
import AccessoriesDrawer from "../../../components/Drawer/AccessoriesDrawer";
import MilestoneReference from "../../../components/Panel/MilestoneReference";
import AccessoryReference from "../../../components/Panel/AccessoryReference";
export default {
  components: {
    "project-info": ProjectInfo,
    "action-panel": ActionPanel,
    "plan-more-drawer": PlanMoreDrawer,
    "accessories-drawer": AccessoriesDrawer,
    "accessory-reference": AccessoryReference,
    "milestone-reference": MilestoneReference,
  },
  props: {
    payPlanId: Number,
    canShowBtn: Boolean,
  },
  data() {
    return {
      icons: icon,
      projectDetail: {
        total_amount: 0,
        client_user: [],
      },
      planDetail: {},
      milestones: [],
      project_name: "",
      event_count: 0,
      reference_method: "使用里程碑导入",
      remark: "",
      accessories: [],
      action_list: [],
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    ...mapActions({
      planSyncDataAction: "planSyncData",
      planGetDetailAction: "planGetDetail",
      planGetUserListAction: "planGetUserList",
      planAddAccessoriesAction: "planAddAccessories",
      planGetMilestoneListAction: "planGetMilestoneList",
      planGetAccessoriesListAction: "planGetAccessoriesList",
      planGetMilestoneActionListAction: "planGetMilestoneActionList",
    }),
    refreshData() {
      Promise.all([
        this.getProjectDetail(),
        this.getMilestoneData(),
        this.getMilestoneAction(),
        this.getDetail(),
        this.getAccessories(),
      ])
        .then((values) => {
          this.projectDetail = values[0];
          this.milestones = values[1];
          this.action_list = values[2];
          this.planDetail = values[3];
          this.accessories = values[4];
          this.event_count = this.milestones.length;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    showDrawer() {
      this.$emit("show-name-drawer", this.payPlanId, this.planDetail.name);
    },
    getProjectDetail() {
      return new Promise((resolve, reject) => {
        this.planGetUserListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getMilestoneData() {
      return new Promise((resolve, reject) => {
        this.planGetMilestoneListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getMilestoneAction() {
      return new Promise((resolve, reject) => {
        this.planGetMilestoneActionListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            res.map((item) => {
              item.create_time = moment(item.create_time).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return item;
            });
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDetail() {
      return new Promise((resolve, reject) => {
        this.planGetDetailAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAccessories() {
      return new Promise((resolve, reject) => {
        this.planGetAccessoriesListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sync() {
      this.planSyncDataAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id: this.payPlanId,
      })
        .then((res) => {
          this.refreshData();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
    add(file) {
      this.planAddAccessoriesAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id: this.payPlanId,
        file_name: file.file_name,
        file_url: file.file_url,
        size: file.size,
      })
        .then((res) => {
          this.planGetAccessoriesListAction({
            employer_id: localStorage.getItem("employer_id"),
            pay_plan_id: this.payPlanId,
          })
            .then((res) => {
              for (let item of res) {
                item.create_time = moment(item.create_time).format(
                  "YYYY/MM/DD HH:mm"
                );
              }
              this.accessories = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    updateMore() {
      this.$refs.planMoreDrawer.open(this.planDetail.more, this.payPlanId);
    },
    refreshInfo() {
      this.planGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id: this.payPlanId,
      })
        .then((res) => {
          this.planDetail = res;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
  },
  computed: {
    planCreateTime: function () {
      return moment(this.planDetail.create_time).format("YYYY-MM-DD HH:mm");
    },
    planUpdateTime: function () {
      return moment(this.planDetail.update_time).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style scoped>
.plan-milestone-page-root-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
}
.plan-milestone-page-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.plan-milestone-page-row-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.plan-milestone-page-row-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.plan-milestone-action-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
}
.action-list-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
.plan-milestone-detail-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.plan-milestone-detail-remark-container {
  flex: 1;
  width: 100%;
  font-size: 14px;
  height: 200px;
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 10px;
}
</style>