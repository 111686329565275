<template>
  <div class="plan-excel-page-root-container">
    <div class="plan-excel-detail-main-container">
      <project-info :data-source="projectDetail" :id="payPlanId" :type="2" />
      <excel-reference :data-source="excels" />
    </div>
    <div class="plan-excel-detail-main-container" style="margin: 0px 50px">
      <div class="plan-excel-page-row" style="margin-bottom: 10px">
        <span
          style="
            font-size: 23px;
            font-weight: bold;
            color: #000000;
            max-width: 300px;
          "
          >{{ planDetail.name }}</span
        >
        <img
          v-if="canShowBtn"
          :src="icons.edit"
          style="width: 18px; height: 18px; cursor: pointer"
          @click="showDrawer"
        />
      </div>
      <div class="plan-excel-page-row">
        <span class="plan-excel-page-row-key">付款计划ID</span>
        <span class="plan-excel-page-row-val">{{
          planDetail.pay_plan_id
        }}</span>
      </div>
      <div class="plan-excel-page-row">
        <span class="plan-excel-page-row-key">创建时间</span>
        <span class="plan-excel-page-row-val">{{ planCreateTime }}</span>
      </div>
      <div class="plan-excel-page-row">
        <span class="plan-excel-page-row-key">创建者</span>
        <span class="plan-excel-page-row-val">{{
          planDetail.create_user_name
        }}</span>
      </div>
      <div class="plan-excel-page-row" style="margin-bottom: 30px">
        <span class="plan-excel-page-row-key">关联的项目</span>
        <span class="plan-excel-page-row-val">{{
          planDetail.project_name
        }}</span>
      </div>
      <div class="plan-excel-page-row">
        <span class="plan-excel-page-row-key">引用里程碑事件</span>
        <span class="plan-excel-page-row-val">无</span>
      </div>
      <div class="plan-excel-page-row">
        <span class="plan-excel-page-row-key">引用方式</span>
        <span class="plan-excel-page-row-val">{{ reference_method }}</span>
      </div>
      <div class="plan-excel-page-row" style="margin-bottom: 20px">
        <span class="plan-excel-page-row-key">最近更新于</span>
        <span class="plan-excel-page-row-val">{{ planUpdateTime }}</span>
      </div>
      <div class="plan-excel-page-row">
        <span class="plan-excel-page-row-key">备注信息</span>
        <span
          v-if="canShowBtn"
          @click="updateMore"
          style="
            font-size: 12px;
            font-weight: 500;
            color: #3b73bb;
            cursor: pointer;
          "
          >更新</span
        >
      </div>
      <div class="plan-excel-detail-remark-container">
        {{ planDetail.more }}
      </div>
    </div>
    <div class="plan-excel-detail-main-container">
      <!-- <Button @click="sync" type="primary" style="margin-bottom: 30px"
        >同步</Button
      > -->
      <a @click="download">下载佐证材料</a>
      <accessory-reference
        :data-source="accessories"
        :can-show-btn="canShowBtn"
        @upload="upload"
      />

      
    </div>
    <!-- <accessory-list :max-height="440" style="flex:1;" /> -->

    <accessories-drawer ref="accessoriesDrawer" @add="add" />
    <plan-more-drawer @refreshInfo="refreshInfo" ref="planMoreDrawer" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import PlanMoreDrawer from "../Drawer/PlanMoreDrawer";
import ActionItem from "../../../components/Item/ActionItem";
import ProjectInfo from "../../../components/Panel/ProjectInfo";
// import AccessoryList from "../../../components/List/AccessoryList";
import ExcelReference from "../../../components/Panel/ExcelReference";
import AccessoriesDrawer from "../../../components/Drawer/AccessoriesDrawer";
import AccessoryReference from "../../../components/Panel/AccessoryReference";
export default {
  components: {
    "action-item": ActionItem,
    "project-info": ProjectInfo,
    // "accessory-list": AccessoryList,
    "excel-reference": ExcelReference,
    "plan-more-drawer": PlanMoreDrawer,
    "accessories-drawer": AccessoriesDrawer,
    "accessory-reference": AccessoryReference,
  },
  props: {
    payPlanId: Number,
    canShowBtn: Boolean,
  },
  data() {
    return {
      icons: icon,
      projectDetail: {
        total_amount: 0,
        client_user: [],
      },
      planDetail: {},
      excels: [],
      accessories: [],
      reference_method: "使用Excel文件导入",
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    ...mapActions({
      planGetDetailAction: "planGetDetail",
      planGetUserListAction: "planGetUserList",
      planGetExcelListAction: "planGetExcelList",
      planAddAccessoriesAction: "planAddAccessories",
      planGetAccessoriesListAction: "planGetAccessoriesList",
    }),
    showDrawer() {
      this.$emit(
        "show-name-drawer",
        this.planDetail.pay_plan_id,
        this.planDetail.name
      );
    },
    refreshData() {
      Promise.all([
        this.getProjectDetail(),
        this.getExcelData(),
        this.getAccessories(),
        this.getDetail(),
      ])
        .then((values) => {
          this.projectDetail = values[0];
          this.excels = values[1];
          this.accessories = values[2];
          this.planDetail = values[3];
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getProjectDetail() {
      return new Promise((resolve, reject) => {
        this.planGetUserListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getExcelData() {
      return new Promise((resolve, reject) => {
        this.planGetExcelListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAccessories() {
      return new Promise((resolve, reject) => {
        this.planGetAccessoriesListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDetail() {
      return new Promise((resolve, reject) => {
        this.planGetDetailAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id: this.payPlanId,
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
    add(file) {
      this.planAddAccessoriesAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id: this.payPlanId,
        file_name: file.file_name,
        file_url: file.file_url,
        size: file.size,
      })
        .then((res) => {
          this.planGetAccessoriesListAction({
            employer_id: localStorage.getItem("employer_id"),
            pay_plan_id: this.payPlanId,
          })
            .then((res) => {
              for (let item of res) {
                item.create_time = moment(item.create_time).format(
                  "YYYY/MM/DD HH:mm"
                );
              }
              this.accessories = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    updateMore() {
      this.$refs.planMoreDrawer.open(this.planDetail.more, this.payPlanId);
    },
    refreshInfo() {
      this.planGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id: this.payPlanId,
      })
        .then((res) => {
          this.planDetail = res;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    download() {
      if (this.planDetail.file_url) {
        window.open(this.planDetail.file_url);
      } else {
        this.$Message.error("未找到佐证材料");
      }
    },
  },
  computed: {
    planCreateTime: function () {
      return moment(this.planDetail.create_time).format("YYYY-MM-DD HH:mm");
    },
    planUpdateTime: function () {
      return moment(this.planDetail.update_time).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style scoped>
.plan-excel-page-root-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
}
.plan-excel-page-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.plan-excel-page-row-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.plan-excel-page-row-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.plan-excel-action-container {
  overflow: hidden;
}
.plan-excel-detail-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.plan-excel-detail-remark-container {
  flex: 1;
  width: 100%;
  font-size: 14px;
  height: 200px;
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 10px;
}
</style>