<template>
  <my-drawer ref="planDrawer" title="计划名称" :transfer="false" :inner="true">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-input
          ref="formName"
          title="名称"
          v-model="name"
          :validate="nameValidate"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import MyDrawer from "../../../components/MyDrawer";
import MyInput from "../../../components/Form/MyInput";
import { mapActions } from "vuex";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
  },
  data() {
    return {
      pay_plan_id: 0,
      name: "",
    };
  },
  methods: {
    ...mapActions({
      planSaveNameAction: "planSaveName",
    }),
    open(pay_plan_id, name) {
      this.name = name;
      this.pay_plan_id = pay_plan_id;
      this.$refs.planDrawer.open();
    },
    close() {
      this.$refs.planDrawer.close();
    },
    submit() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.planSaveNameAction({
            employer_id: localStorage.getItem("employer_id"),
            pay_plan_id: this.pay_plan_id,
            name: this.name,
          })
            .then((res) => {
              this.$Message.success("重命名成功");
              this.$parent.$parent.refresh();
              this.$refs.planDrawer.close();
            })
            .catch((error) => {
              console.log(error);
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.name == "") {
          reject("请填写计划名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.board-form-image {
  width: 100px;
  height: 118px;
}
</style>