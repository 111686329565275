<template>
  <div class="milestone-reference-root-container">
    <div class="milestone-reference-head-container">
      <span
        style="
          font-size: 12px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 10px;
        "
        >引用的里程碑事件</span
      >
    </div>
    <milestone-event-item
      v-for="item in dataSource"
      :key="item.id"
      :data-source="item"
    ></milestone-event-item>
  </div>
</template>

<script>
import EventItem from "../../views/Plan/Item/EventItem";
import MilestoneEventItem from "../../views/Plan/Item/MilestoneEventItem";
export default {
  components: {
    "milestone-event-item": MilestoneEventItem,
  },
  props: {
    dataSource: Array,
  },
};
</script>

<style scoped>
.milestone-reference-root-container {
  display: flex;
  flex-direction: column;
  max-height: 260px;
  overflow-y: scroll;
}
.milestone-reference-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>