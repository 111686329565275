<template>
  <Modal
    v-model="isShowModal"
    footer-hide
    :closable="false"
    :width="1200"
    @on-visible-change="onVisibleChange"
  >
    <div v-if="isShowModal" class="plan-pay-modal-root-container">
      <div class="plan-pay-modal-head-container">
        <span style="font-size: 12px; font-weight: bold; color: #7d7d7d"
          >付款计划ID：{{ pay_plan_id }}</span
        >
        <div
          v-if="canShowBack"
          style="display: flex; flex-direction: row; align-items: center"
        >
          <div class="plan-pay-modal-send-button" @click="backToPlan">
            退回至付款计划
          </div>
        </div>
        <div
          v-if="canShowBtn"
          style="display: flex; flex-direction: row; align-items: center"
        >
          <div class="plan-pay-modal-send-button" @click="sendQueue">
            发送付款计划到处理队列
          </div>
          <Icon
            type="ios-trash"
            size="28"
            style="cursor: pointer"
            @click="deleteSelf"
          />
        </div>
      </div>
      <plan-excel-page
        ref="excel"
        v-if="type == 1"
        :pay-plan-id="pay_plan_id"
        :can-show-btn="canShowBtn"
        @show-name-drawer="showNameDrawer"
      />
      <plan-milestone-page
        ref="milestone"
        v-else-if="type == 2"
        :pay-plan-id="pay_plan_id"
        :can-show-btn="canShowBtn"
        @show-name-drawer="showNameDrawer"
      />

      <plan-name-drawer ref="planNameDrawer" />
    </div>
  </Modal>
</template>

<script>
import PlanExcelPage from "../Page/PlanExcelPage";
import PlanNameDrawer from "../Drawer/PlanNameDrawer";
import PlanMilestonePage from "../Page/PlanMilestonePage";
import { mapActions } from "vuex";
export default {
  components: {
    "plan-excel-page": PlanExcelPage,
    "plan-name-drawer": PlanNameDrawer,
    "plan-milestone-page": PlanMilestonePage,
  },
  data() {
    return {
      isShowModal: false,
      canShowBtn: false,
      canShowBack: false,

      type: 1,
      pay_plan_id: 0,
    };
  },
  methods: {
    ...mapActions({
      planSetStateAction: "planSetState",
      planSendQueueAction: "planSendQueue",
    }),
    open(dataSource, canShowBtn, canShowBack) {
      this.type = dataSource.type;
      this.pay_plan_id = dataSource.pay_plan_id;

      this.canShowBtn = canShowBtn || false;
      this.canShowBack = canShowBack || false;

      this.isShowModal = true;
    },
    showNameDrawer(id, name) {
      this.$refs.planNameDrawer.open(id, name);
    },
    onVisibleChange(flag) {
      // 当 modal 关闭时自动隐藏 drawer
      if (!flag) {
        this.$refs.planNameDrawer && this.$refs.planNameDrawer.close();
      }
    },
    refresh() {
      if (this.type == 1) {
        this.$refs.excel.refreshData();
      } else {
        this.$refs.milestone.refreshData();
      }
    },
    sendQueue() {
      this.planSendQueueAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id_arr: [this.pay_plan_id],
      })
        .then((res) => {
          this.$parent.$parent.refresh();
          this.$Message.success("发送成功");
          this.isShowModal = false;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    deleteSelf() {
      this.planSetStateAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id_arr: [this.pay_plan_id],
        state: -1,
      })
        .then((res) => {
          this.$parent.$parent.refresh();
          this.$Message.success("作废成功");
          this.isShowModal = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Message.error(error);
        });
    },
    backToPlan() {
      this.planSetStateAction({
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_id_arr: [this.pay_plan_id],
        state: 1,
      })
        .then((res) => {
          this.$Message.success("退回成功");
          this.isShowModal = false;
          this.$parent.$parent.$parent.getList(2);
        })
        .catch((error) => {
          console.log(error);
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.plan-pay-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 700px;
  overflow: hidden;
}
.plan-pay-modal-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.plan-pay-modal-send-button {
  width: 200px;
  height: 35px;
  border-radius: 8px;
  background-color: #e7edf5;
  color: #424242;
  font-weight: bold;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>